/* Typography imported from Google Fonts */
@import url('https://fonts.googleapis.com/css?family=Playfair+Display|Source+Sans+Pro:200,400');

h1, h2, h3, h4, h5, h6 {
  font-family: 'Playfair Display', serif;
}

p, a {
  font-family: 'Source Sans Pro', sans-serif;
}

/* Generic styles */
html {
  scroll-behavior: smooth;
  background-color:rgb(110, 110, 243)
}

.root {
	scroll-behavior: smooth;
      }

a {
  background-color: rgb(32, 141, 218);
  text-decoration: none;
  color: white;
  border-radius: .25rem;
  text-align: center;
  display: inline-block;
  transition: all .3s;
}

a:hover {
  opacity: .6;
}


/* Styles for the hero image */
header {
  /* Photo by mnm.all on Unsplash */
  background: url('./assets/images/sunshine.jpeg') center;
  background-size: cover;
  padding: 4rem 2rem;
  /* grid styles */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  align-items: center;
}

/* Styles for the hero image */
.hero {
  /* Photo by mnm.all on Unsplash */
  background: url('https://images.unsplash.com/photo-1518176258769-f227c798150e') center;
  background-size: cover;
  padding: 4rem 2rem;
  /* grid styles */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  align-items: center;
}

.hero > * {
  color: white;
}

.hero > h1 {
  font-size: 4rem;
  padding-bottom: 1rem;
}

.hero > a {
  font-size: 8rem;
  padding-bottom: 1rem;
}

.hero > article > p {
  font-size: 1.5rem;
  font-weight: 200;
}

.hero > article > a {
  padding: 1rem;
  margin-top: .75rem;
}

/* breweries styles */
.breweries {
  padding: 2rem;
  align-items: center;
  position: relative;
}

.breweries > ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 1rem;
}

.breweries > ul > li {
  border: 1px solid #E2E2E2;
  border-radius: .5rem;
}

.breweries > ul > li > figure {
  max-height: 220px;
  overflow: hidden;
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
  position: relative;
}

.breweries > ul > li > figure > img {
  width: 100%;
}

.breweries > ul > li > figure > figcaption {
  position: absolute;
  bottom: 0;
  /* background-color: rgba(0,0,0,.7); */
  width: 100%;
}

.breweries > ul > li > figure > figcaption > h3 {
  color: white;
  padding: .75rem;
  font-size: 1.25rem;
}

.breweries > ul > li > p {
  font-size: 1rem;
  line-height: 1.5;
  padding: 1rem .75rem;
  color: #666666;
}

.breweries > ul > li > a {
  padding: .5rem 1rem;
  margin: .5rem;
}

/* footer */
footer {
  background-color: rgb(51, 51, 51);
  padding: .75rem;
  color: white;
  text-align: center;
  font-size: .75rem;
  height:100px;
}


input[type="text"],
input[type="password"],
button,
textarea {
  font-family: 'Nunito', sans-serif;
  font-size: 22px;
  padding: 0.25rem;
  border-radius: 0.5rem;
}

label,
button {
  margin-top: 1rem;
}

button {
  padding: 0.5rem;
}

.instructions {
    font-size: 0.75rem;
    border-radius: 0.5rem;
    background: #000;
    color: #fff;
    padding: 0.25rem;
    position: relative;
    bottom: -10px;
}

.instructions > svg {
    margin-right: 0.25rem;
}

.offscreen {
    position: absolute;
    left: -9999px;
}

.hide {
    display: none;
}

.valid {
    color: limegreen;
    margin-left: 0.25rem;
}

.invalid {
    color: red;
    margin-left: 0.25rem;
}

.errmsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.line {
    display: inline-block;
}


form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-grow: 1;
  padding-bottom: 1rem;
}
